import { EditOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, DatePicker, DatePickerProps, Form, Input } from "antd";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { UpdatePeopleDocument } from "../../../../generated/graphql";
import { useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import DOMPurify from "dompurify";

interface PersonInfosProps {
  personId: string;
  personName: string;
  personBirthDate?: string | null | undefined;
  personJobTitle?: string | null | undefined;
  personDescription?: string | null | undefined;
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
  newPerson?: any;
  setNewPerson?: React.Dispatch<React.SetStateAction<any>>;
}
const PersonInfos: React.FC<PersonInfosProps> = ({
  personId,
  personName,
  personBirthDate,
  personJobTitle,
  personDescription,
  setRefetch,
  newPerson,
  setNewPerson,
}) => {
  const navigate = useNavigate();
  const { action } = useParams();
  const [editMode, setEditMode] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [formatBirthDate, setFormatBirthDate] = React.useState<
    string | null | undefined
  >(personBirthDate);
  const [updatePerson, { loading: updating, error: updateError }] =
    useMutation(UpdatePeopleDocument);

  useEffect(() => {
    if (!editMode && !personId.length) {
      setEditMode(true);
    }
  }, [editMode, personId]);

  const onDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    if (typeof dateString === "string" && dateString.length > 0) {
      if (action === "create" && setNewPerson) {
        setNewPerson({ ...newPerson, personBirthDate: dateString });
      } else {
        setFormatBirthDate(dateString);
      }
    }
  };

  const handleSubmit = async (values: any) => {
    if (action !== "create") {
      const dateTime = new Date().toISOString();
      const { birthDate, ...rest } = values;
      const birthDateValue = formatBirthDate
        ? formatBirthDate
        : personBirthDate;
      try {
        const response = await updatePerson({
          variables: {
            update: {
              ...rest,
              personBirthDate: birthDateValue,
              personUpdatedAt: dateTime,
            },
            where: { personId: personId },
          },
        });
        if (response.errors) {
          setErrorMessage(
            "ERROR TO UPDATE PERSON" + response.errors[0].message
          );
        } else {
          if (rest.personName !== personName) {
            navigate(`/person/${DOMPurify.sanitize(rest.personName)}`, {
              state: { personId: personId },
            });
          } else {
            setEditMode(false);
            setRefetch(true);
          }
        }
      } catch (e) {
        console.error("ERROR UPDATING PERSON'S INFOS: " + e);
      }
    }
  };

  return (
    <Card
      className="card-children"
      title={
        <>
          <UserOutlined /> Person
        </>
      }
      extra={
        <Button type="link" onClick={() => setEditMode(!editMode)}>
          {editMode ? personId !== "" ? "Cancel" : "" : <EditOutlined />}
        </Button>
      }
    >
      {editMode ? (
        <Form
          layout="vertical"
          initialValues={{
            personName: personName,
            birthDate: personBirthDate
              ? dayjs(personBirthDate, "YYYY-MM-DD")
              : undefined,
            personJobTitle: personJobTitle,
            personDescription: personDescription,
          }}
          onFinish={handleSubmit}
        >
          <FormItem
            label="Name"
            name="personName"
            rules={[{ required: true, message: "Please enter person's name" }]}
          >
            <Input
              onChange={(e) => {
                if (action === "create" && setNewPerson)
                  setNewPerson({
                    ...newPerson,
                    personName: DOMPurify.sanitize(e.target.value),
                  });
              }}
            />
          </FormItem>
          <FormItem label="Birth Date" name="birthDate">
            <DatePicker onChange={onDateChange} />
          </FormItem>
          <FormItem label="Job Title" name="personJobTitle">
            <Input
              onChange={(e) => {
                if (action === "create" && setNewPerson)
                  setNewPerson({
                    ...newPerson,
                    personJobTitle: DOMPurify.sanitize(e.target.value),
                  });
              }}
            />
          </FormItem>
          <FormItem label="Description" name="personDescription">
            <TextArea
              rows={3}
              onChange={(e) => {
                if (action === "create" && setNewPerson)
                  setNewPerson({
                    ...newPerson,
                    personDescription: DOMPurify.sanitize(e.target.value),
                  });
              }}
            />
          </FormItem>
          <Form.Item>
            {action === "create" ? (
              ""
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Save
              </Button>
            )}
            {errorMessage && (
              <p
                style={{
                  textAlign: "center",
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                {DOMPurify.sanitize(errorMessage)}
              </p>
            )}
          </Form.Item>
        </Form>
      ) : (
        <>
          {personName && <p>Name: {DOMPurify.sanitize(personName)}</p>}
          {personBirthDate && (
            <p>
              Birth Date:{" "}
              {dayjs(personBirthDate, "YYYY-MM-DD").format("MM-DD-YYYY")}
            </p>
          )}
          {personJobTitle && (
            <p>Job Title: {DOMPurify.sanitize(personJobTitle)}</p>
          )}
          {personDescription && (
            <p>Description: {DOMPurify.sanitize(personDescription)}</p>
          )}
        </>
      )}
    </Card>
  );
};

export default PersonInfos;
