import { useMutation } from "@apollo/client";
import { Button, message, Upload, UploadProps } from "antd";
import React from "react";
import { ImportContactsDocument } from "../../generated/graphql";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile, UploadFile } from "antd/es/upload";
import DOMPurify from "dompurify";

interface UploadInputProps {
  loggedUser: string;
  onCancel?: (success: boolean) => void;
}

const UploadInput: React.FC<UploadInputProps> = ({ loggedUser, onCancel }) => {
  const [importContacts] = useMutation(ImportContactsDocument);

  const handleUpload = async (file: UploadFile) => {
    try {
      const isVCF =
        file.name.toLowerCase().endsWith(".vcf") || file.type === "text/vcard";
      const isCSV =
        file.name.toLowerCase().endsWith(".csv") || file.type === "text/csv";

      if (!isVCF && !isCSV) {
        message.error(
          `${DOMPurify.sanitize(file.name)} is not a VCF or CSV file`
        );
        return Upload.LIST_IGNORE;
      }

      const response = await importContacts({
        variables: { file, userId: loggedUser },
        context: {
          fetchOptions: {
            useUpload: true,
          },
        },
      });

      console.log("GraphQL Response:", Object.entries(response));
      let formattedRes = Object.entries(response);
      let resSuccess = formattedRes[0][1];

      if (!resSuccess) {
        throw new Error("No data returned from the server.");
      }

      if (resSuccess) {
        message.success(`${file.name} uploaded successfully!`);
        onCancel && onCancel(true);
        console.log(`Imported ${formattedRes[2][1]} contacts.`);
      } else {
        message.error(
          `Failed to import contacts: ${formattedRes[3][1].join(", ")}`
        );
      }
    } catch (error: any) {
      console.error("Upload error:", error);
      message.error(
        `File upload failed: ${
          DOMPurify.sanitize(error.message) || "Unknown error"
        }`
      );
    }

    return false;
  };

  const props: UploadProps = {
    beforeUpload: handleUpload,
    showUploadList: false,
    multiple: false,
    accept: ".vcf, .csv",
  };

  return (
    <Upload {...props}>
      <Button icon={<UploadOutlined />} type="primary">
        Select a File
      </Button>
    </Upload>
  );
};

export default UploadInput;
