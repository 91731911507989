import { Badge, Calendar, Card, List, Spin, Tag } from "antd";
import React, { useEffect, useState } from "react";
import DoughnutChart from "../../reusable-components/DonutChart";
import {
  UpdateTasksDocument,
  useGetPersonTasksQuery,
  useGetTaskStatusQuery,
} from "../../../generated/graphql";
import { getCookie } from "../../hooks/cookie";
import { useMutation } from "@apollo/client";
import dayjs from "dayjs";
import "../../../style/dashboard.scss";
import { useNavigate } from "react-router-dom";
import CalendarComponent from "./Calendar";
import { StarFilled } from "@ant-design/icons";
import DOMPurify from "dompurify";

const Dashboard = () => {
  const navigate = useNavigate();
  const [dataChart, setDataChart] = useState<any[]>([]);
  const [taskList, setTaskList] = useState<any[]>([]);
  const [calendarList, setCalendarList] = useState<any[]>([]);
  const [updateTasks, setUpdateTasks] = useState<boolean>(false);
  const backgroundColors = ["#bae637", "#4096ff", "#36cfc9", "#ff4d4f"];
  const userCookie = getCookie("user");
  const userObj = userCookie
    ? JSON.parse(decodeURIComponent(userCookie))
    : null;
  const loggedUserId = userObj ? userObj.userId : null;
  const {
    data: tasks,
    loading: tasksLoading,
    refetch: refetchTasks,
  } = useGetPersonTasksQuery({
    variables: {
      where: {
        taskOwner: { userId: DOMPurify.sanitize(loggedUserId || "") },
      },
    },
  });
  const { data: taskStatus } = useGetTaskStatusQuery();
  const taskStatuses = taskStatus?.__type?.enumValues;
  const [updateTask] = useMutation(UpdateTasksDocument);

  useEffect(() => {
    if (updateTasks) {
      refetchTasks();
      setUpdateTasks(false);
    }
    if (tasks?.tasks && taskStatuses) {
      //For Chart
      const statusCounts = tasks.tasks.reduce(
        (acc: { [key: string]: number }, task) => {
          acc[task.taskStatus] = (acc[task.taskStatus] || 0) + 1;
          return acc;
        },
        {}
      );

      setDataChart(
        taskStatuses.map((status) => ({
          label: status.name.replace(/_/g, " "),
          amount: statusCounts[status.name] || 0,
        }))
      );

      //For Task List
      const prioritizedTasks = [
        ...tasks.tasks.filter(
          (task) =>
            task.taskFavorite &&
            task.taskStatus ===
              taskStatuses.find((status) => status.name === "IN_PROGRESS")?.name
        ),
        ...tasks.tasks.filter(
          (task) =>
            task.taskFavorite &&
            task.taskStatus ===
              taskStatuses.find((status) => status.name === "PENDING")?.name
        ),
        ...tasks.tasks.filter(
          (task) =>
            !task.taskFavorite &&
            task.taskStatus ===
              taskStatuses.find((status) => status.name === "IN_PROGRESS")?.name
        ),
        ...tasks.tasks.filter(
          (task) =>
            !task.taskFavorite &&
            task.taskStatus ===
              taskStatuses.find((status) => status.name === "PENDING")?.name
        ),
      ];

      setTaskList(prioritizedTasks.slice(0, 5));

      //For Calendar
      const dueDateTasks = tasks.tasks
        .filter(
          (task) =>
            task.taskDueDate &&
            task.taskStatus !== "COMPLETED" &&
            task.taskStatus !== "CANCELLED"
        )
        .map((task) => ({
          id: task.taskId,
          title: task.taskName,
          date: task.taskDueDate,
          color: task.taskColor,
          favorite: task.taskFavorite,
        }));
      setCalendarList(dueDateTasks);
    }
    if (!tasks?.tasks[0]) {
      setDataChart([]);
    }
  }, [taskStatuses, tasks, updateTasks]);

  const findTask = (taskId: string) => {
    return taskList?.find((task) => task.taskId === taskId);
  };

  return (
    <div className="dashboard">
      <div className="page-title-header">
        <h1
          style={{
            backgroundColor: "white",
            width: "fit-content",
            padding: "16px 16px",
            borderRadius: "8px",
            color: "#141414",
            opacity: 0.8,
          }}
        >
          DASHBOARD
        </h1>
      </div>
      <div className="dashboard-content">
        <Card
          title="Work in progress"
          extra={<a href="/tasks">View All</a>}
          className="card tasks-list"
        >
          <List
            loading={tasksLoading}
            dataSource={taskList}
            renderItem={(task) => (
              <List.Item
                className="task-item"
                onClick={(e) => {
                  if ((e.target as Element).closest(".non-clickable-column")) {
                    e.stopPropagation();
                    return;
                  }
                  navigate(
                    `/person/${DOMPurify.sanitize(
                      task.taskPerson[0]?.personName || ""
                    )}`,
                    {
                      state: { personId: task.taskPerson[0]?.personId },
                    }
                  );
                }}
              >
                <div>
                  {task.taskFavorite ? (
                    <b className="favorite-task">
                      <StarFilled
                        style={{
                          color: "#ffc53d",
                          fontSize: "24px",
                          paddingRight: "8px",
                        }}
                      />{" "}
                      {DOMPurify.sanitize(task.taskName)}
                    </b>
                  ) : (
                    <Badge
                      color={task.taskColor ? task.taskColor : "#1677ff"}
                      text={DOMPurify.sanitize(task.taskName)}
                      style={{ fontWeight: "bold" }}
                    />
                  )}
                  {task.taskDescription && (
                    <p className="text" style={{ color: "#8c8c8c" }}>
                      {DOMPurify.sanitize(task.taskDescription)}
                    </p>
                  )}
                  {task.taskDueDate && (
                    <p>
                      Due :{" "}
                      {DOMPurify.sanitize(
                        dayjs(task.taskDueDate).format("MM-DD-YYYY")
                      )}
                    </p>
                  )}
                </div>
                <div className="non-clickable-column">
                  {taskStatuses?.map((status) => (
                    <Tag.CheckableTag
                      key={`${task.taskId}-${status.name}`}
                      checked={
                        findTask(task.taskId)?.taskStatus === status.name &&
                        findTask(task.taskId)?.taskId === task.taskId
                          ? true
                          : false
                      }
                      onChange={async (checked) => {
                        if (checked) {
                          await updateTask({
                            variables: {
                              update: {
                                taskStatus: status.name,
                                taskUpdatedAt: dayjs().format("YYYY-MM-DD"),
                              },
                              where: {
                                taskId: task.taskId,
                                taskPerson_SINGLE: {
                                  personId: task.taskPerson.personId,
                                },
                                taskOwner: { userId: loggedUserId },
                              },
                            },
                          }).then(() => {
                            refetchTasks();
                          });
                        }
                      }}
                    >
                      {DOMPurify.sanitize(status.name.replace(/_/g, " "))}
                    </Tag.CheckableTag>
                  ))}
                </div>
              </List.Item>
            )}
          ></List>
        </Card>
        <Card
          title="Tasks Progress"
          extra={"Total : " + tasks?.tasks?.length}
          className="card chart"
        >
          {tasksLoading ? (
            <Spin></Spin>
          ) : (
            <div className="donut">
              <DoughnutChart dataList={dataChart} colors={backgroundColors} />
            </div>
          )}
        </Card>
      </div>
      <CalendarComponent
        events={calendarList}
        setRefetchTasks={setUpdateTasks}
      />
    </div>
  );
};

export default Dashboard;
