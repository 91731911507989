import { PlusCircleOutlined, StarFilled } from "@ant-design/icons";
import { Badge, Button, Calendar, Card, List, Modal, Tag } from "antd";
import React, { useState } from "react";
import "../../../style/calendar.scss";
import {
  SortDirection,
  useGetPersonTasksQuery,
} from "../../../generated/graphql";
import { getCookie } from "../../hooks/cookie";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import TaskForm from "../tasks/TaskForm";
import DOMPurify from "dompurify";

interface CalendarEvent {
  id: number;
  title: string;
  date: string;
  color?: string;
  favorite?: boolean;
}

interface CalendarComponentProps {
  events: CalendarEvent[];
  setRefetchTasks?: any;
}

const CalendarComponent: React.FC<CalendarComponentProps> = ({
  events,
  setRefetchTasks,
}) => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTaskFormVisible, setIsTaskFormVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | undefined>(
    undefined
  );
  const userCookie = getCookie("user");
  const userObj = userCookie
    ? JSON.parse(decodeURIComponent(userCookie))
    : null;
  const loggedUserId = userObj ? userObj.userId : null;
  const {
    data: tasks,
    loading: tasksLoading,
    refetch: refetchTasks,
  } = useGetPersonTasksQuery({
    variables: {
      where: {
        taskOwner: { userId: DOMPurify.sanitize(loggedUserId || "") },
        taskDueDate: DOMPurify.sanitize(
          dayjs(selectedDate).format("YYYY-MM-DD")
        ),
      },
      options: {
        sort: [{ taskStatus: SortDirection.Desc }],
      },
    },
  });

  const getEventsForDate = (date: string) => {
    return events.filter((event) => event.date === date);
  };

  const dateCellRender = (date: any) => {
    const formattedDate = date.format("YYYY-MM-DD");
    const dayEvents = getEventsForDate(formattedDate);

    return (
      <ul className="calendar-events">
        {dayEvents.map((event) => (
          <li key={event.id} style={{ listStyle: "none" }}>
            {event.favorite ? (
              <b className="favorite-task">
                <StarFilled
                  style={{
                    color: "#ffc53d",
                    fontSize: "24px",
                    paddingRight: "8px",
                  }}
                />{" "}
                {DOMPurify.sanitize(event.title)}
              </b>
            ) : (
              <Badge
                className="other-task"
                color={event.color ? event.color : "#1677ff"}
                text={DOMPurify.sanitize(event.title)}
              />
            )}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Card className="calendar-container">
      <Calendar
        cellRender={dateCellRender}
        onSelect={(date, selectInfo) => {
          setSelectedDate(date);
          setIsModalVisible(true);
        }}
      />
      <Modal
        title={
          "Event Details : " +
          DOMPurify.sanitize(selectedDate?.format("MM-DD-YYYY") || "")
        }
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setRefetchTasks(true);
        }}
        footer={null}
      >
        <List>
          {tasks?.tasks?.map((task) => (
            <List.Item
              key={task.taskId}
              onClick={(e) => {
                navigate(
                  `/person/${DOMPurify.sanitize(
                    task.taskPerson[0]?.personName || ""
                  )}`,
                  {
                    state: { personId: task.taskPerson[0]?.personId },
                  }
                );
              }}
              className="task-item"
            >
              {task.taskFavorite ? (
                <b className="favorite-task">
                  <StarFilled
                    style={{
                      color: "#ffc53d",
                      fontSize: "24px",
                      paddingRight: "8px",
                    }}
                  />{" "}
                  {task.taskName}
                </b>
              ) : (
                <Badge
                  color={task.taskColor ? task.taskColor : "#1677ff"}
                  text={<>{DOMPurify.sanitize(task.taskName)}</>}
                />
              )}
              <Tag style={{ marginLeft: "8px" }}>
                {DOMPurify.sanitize(task.taskStatus.replace(/_/g, " "))}
              </Tag>
            </List.Item>
          ))}
        </List>
        <Button type="primary" onClick={() => setIsTaskFormVisible(true)}>
          <PlusCircleOutlined /> Add
        </Button>
      </Modal>
      <TaskForm
        visible={isTaskFormVisible}
        loggedUserId={loggedUserId}
        onCancel={() => {
          setIsTaskFormVisible(false);
          refetchTasks();
        }}
        selectedDueDate={selectedDate?.format("YYYY-MM-DD")}
      />
    </Card>
  );
};

export default CalendarComponent;
