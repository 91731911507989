import { Button } from "antd";
import React, { useState } from "react";
import {
  useGetEmailTypesQuery,
  useGetPhoneTypesQuery,
} from "../../generated/graphql";
import { CheckOutlined } from "@ant-design/icons";
import "../../style/copyCSVFormat.scss";
import DOMPurify from "dompurify";

const CopyCSVFormat = () => {
  const [copied, setCopied] = useState(false);
  const { data: emailType } = useGetEmailTypesQuery();
  const { data: phoneType } = useGetPhoneTypesQuery();
  const emailTypes = emailType?.__type?.enumValues;
  const phoneTypes = phoneType?.__type?.enumValues;

  const emailTypeList = emailTypes
    ?.map((type) => `\t${DOMPurify.sanitize(type.name)}_email`)
    .join("");
  const phoneTypeList = phoneTypes
    ?.map((type) => `\t${DOMPurify.sanitize(type.name)}_phone`)
    .join("");

  const copyToClipboard = () => {
    const csvHeader = `name\tprimary_email\tprimary_phone\tdescription\tbirthdate\tjob_title${emailTypeList}${phoneTypeList}`;
    const exampleRow = `(Required) Full Name\t(Required) email@example.com\t(Required) 1234567890\t(Optional) Text\t(Optional) YYYY-MM-DD\t(Optional) Text\t`;
    const contentToCopy = `${csvHeader}\n${exampleRow}`;

    navigator.clipboard
      .writeText(contentToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 10000);
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
      });
  };

  return (
    <div className="copy-csv-format">
      <Button onClick={copyToClipboard} type="default">
        Copy Header
      </Button>
      {copied && (
        <p className="confirm-text">
          Copied <CheckOutlined style={{ color: "#52c41a" }} />
        </p>
      )}
    </div>
  );
};

export default CopyCSVFormat;
