import React, { CSSProperties } from "react";
import UploadInput from "../../reusable-components/UploadInput";
import { Modal, Card, theme, CollapseProps, Collapse } from "antd";
import { InfoCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import CopyCSVFormat from "../../reusable-components/CopyCSVFormat";
import DOMPurify from "dompurify";

interface UploadContactsFormProps {
  visible: boolean;
  loggedUserId: string;
  onCancel: (success: boolean) => void;
}

const UploadContactsForm: React.FC<UploadContactsFormProps> = ({
  visible,
  loggedUserId,
  onCancel,
}) => {
  const { token } = theme.useToken();
  const safeUserId = DOMPurify.sanitize(loggedUserId);

  const getItems: (panelStyle: CSSProperties) => CollapseProps["items"] = (
    panelStyle
  ) => [
    {
      key: "1",
      label: DOMPurify.sanitize("From iPhone (iOS)"),
      children: (
        <ol>
          <li>
            <p>Open the Contacts app.</p>
          </li>
          <li>
            <p>Tap on the Lists tab at the top.</p>
          </li>
          <li>
            <p>Select All iCloud (or the contact group you want to export).</p>
          </li>
          <li>
            <p>Tap the Export button.</p>
          </li>
          <li>
            <p>
              Click Done and Choose where to save or share the <b>.vcf</b> file
              (Files, AirDrop, Email, etc.).
            </p>
          </li>
          <li>
            <p>
              Now that you have saved your <b>.vcf</b> file, you can import it
              into Brijmore.
            </p>
          </li>
        </ol>
      ),
      style: panelStyle,
    },
    {
      key: "2",
      label: DOMPurify.sanitize("From Android"),
      children: (
        <ol>
          <li>
            <p>Open the Contacts app.</p>
          </li>
          <li>
            <p>Tap the Menu (☰) or More (⋮) button.</p>
          </li>
          <li>
            <p>Select Settings {">"} Export.</p>
          </li>
          <li>
            <p>
              Choose Export as <b>.vcf</b> file.
            </p>
          </li>
          <li>
            <p>Save it to your phone storage or Google Drive.</p>
          </li>
          <li>
            <p>
              Now that you have saved your <b>.vcf</b> file, you can import it
              into Brijmore.
            </p>
          </li>
        </ol>
      ),
      style: panelStyle,
    },
    {
      key: "3",
      label: DOMPurify.sanitize("CSV Format"),
      children: (
        <ol>
          <li>
            <p>Click "Copy Header" to copy the column names.</p>
            <CopyCSVFormat />
          </li>
          <li>
            <p>Paste them into Google Sheets, Excel, or any table app.</p>
          </li>
          <li>
            <p>Fill in the data under each column.</p>
          </li>
          <li>
            <p>
              Export the file as a <b>.csv</b> format (Comma-Separated Values).
            </p>
          </li>
          <li>
            <p>
              Now that you have saved your <b>.csv</b> file, you can import it
              into Brijmore.
            </p>
          </li>
        </ol>
      ),
      style: panelStyle,
    },
  ];

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };

  return (
    <Modal
      title={DOMPurify.sanitize("Import Contacts")}
      open={visible}
      footer={null}
      onCancel={() => onCancel(false)}
      className="upload-contacts-form"
    >
      <Card style={{ maxWidth: "800px", width: "100%" }}>
        <UploadInput loggedUser={safeUserId} onCancel={onCancel} />
        <p>
          <InfoCircleOutlined style={{ color: "#faad14" }} /> Format <b>.vcf</b>{" "}
          and <b>.csv</b> only
        </p>
        <br />
        <p style={{ color: "#8c8c8c" }}>
          <QuestionCircleOutlined /> Help
        </p>
        <Collapse
          bordered={false}
          style={{ background: token.colorBgContainer }}
          items={getItems(panelStyle)}
        />
      </Card>
    </Modal>
  );
};

export default UploadContactsForm;
