import {
  CheckSquareOutlined,
  EditOutlined,
  PlusCircleOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  ColorPicker,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  List,
  Select,
  Tag,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import {
  CreateTasksDocument,
  UpdateTasksDocument,
  useGetTaskStatusQuery,
} from "../../../../generated/graphql";
import { useMutation } from "@apollo/client";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import "../../../../style/person-tasks-component.scss";
import { useParams } from "react-router-dom";
import { Color } from "antd/es/color-picker";
import PersonTaskList from "./personTaskComponents/PersonTaskList";
import DOMPurify from "dompurify";

interface PersonTasksProps {
  loggedUserId: string;
  personId: string;
  personTasks?: any[] | undefined;
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
  refetchTasks?: () => void;
}

const PersonTasks: React.FC<PersonTasksProps> = ({
  loggedUserId,
  personId,
  personTasks,
  setRefetch,
  refetchTasks,
}) => {
  const { action } = useParams();
  const [form] = Form.useForm();
  const [editMode, setEditMode] = useState<boolean>(false);
  const { data: taskStatus } = useGetTaskStatusQuery();
  const taskStatuses = taskStatus?.__type?.enumValues || [];
  const [createTasks] = useMutation(CreateTasksDocument);
  const [colorHex, setColorHex] = useState<string | Color>("#1677ff");
  const [dueDate, setDueDate] = useState<string | null | undefined>(null);
  const [favorite, setFavorite] = useState<boolean>(false);

  const hexString = useMemo<string>(
    () => (typeof colorHex === "string" ? colorHex : colorHex?.toHexString()),
    [colorHex]
  );

  const onDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    if (typeof dateString === "string" && dateString.length > 0) {
      setDueDate(dateString);
    }
  };

  const handleFormSubmit = async (values: any) => {
    try {
      const createData = {
        ...values,
        taskId: uuidv4(),
        taskCreatedAt: dayjs().format("YYYY-MM-DD"),
        taskUpdatedAt: dayjs().format("YYYY-MM-DD"),
        taskOwner: {
          connect: {
            where: {
              node: {
                userId: loggedUserId,
              },
            },
          },
        },
        taskPerson: {
          connect: {
            where: {
              node: {
                personId: personId,
              },
            },
          },
        },
      };

      await createTasks({
        variables: {
          input: {
            ...createData,
            taskDueDate: dueDate ? dueDate : undefined,
            taskColor: hexString ? hexString : "#1677ff",
            taskFavorite: favorite,
          },
        },
      }).then((res) => {
        if (!res.errors) {
          form.resetFields();
          setEditMode(false);
          setRefetch(true);
        }
      });
    } catch (error) {
      console.error("Error creating a task", error);
    }
  };

  return (
    <Card
      title={
        <>
          <CheckSquareOutlined /> Tasks
        </>
      }
      className="card-container task-card"
      extra={
        action !== "create" && (
          <Button type="link" onClick={() => setEditMode(!editMode)}>
            {editMode ? (
              "Cancel"
            ) : (
              <>
                <PlusCircleOutlined /> Add
              </>
            )}
          </Button>
        )
      }
    >
      {editMode && (
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFormSubmit}
          initialValues={{
            taskName: "",
            taskDescription: "",
            taskStatus: "PENDING",
          }}
        >
          <Form.Item
            label="Mark as Priority :"
            name="taskFavorite"
            className="favorite-checkbox"
          >
            <Checkbox
              className="platform-checkbox"
              onChange={(e) => {
                setFavorite(e.target.checked);
              }}
            >
              {favorite ? (
                <StarFilled
                  style={{
                    color: "#ffc53d",
                    fontSize: "32px",
                  }}
                />
              ) : (
                <StarOutlined
                  style={{
                    color: "#ffc53d",
                    fontSize: "32px",
                  }}
                />
              )}
            </Checkbox>
          </Form.Item>
          <Form.Item
            label="Task Name"
            name="taskName"
            rules={[{ required: true, message: "Task Name is required" }]}
          >
            <Input placeholder="Enter task name" />
          </Form.Item>
          <Form.Item label="Task Description" name="taskDescription">
            <Input.TextArea placeholder="Enter task description" />
          </Form.Item>
          <Form.Item
            label="Task Status"
            name="taskStatus"
            rules={[{ required: true, message: "Task Status is required" }]}
          >
            <Select placeholder="Select a task status">
              {taskStatuses &&
                taskStatuses.map((status: any) => (
                  <Select.Option key={status.name} value={status.name}>
                    {DOMPurify.sanitize(status.name.replace(/_/g, " "))}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="Task Due Date" name="taskDueDate">
            <DatePicker
              style={{ width: "100%" }}
              placeholder="Select due date"
              onChange={onDateChange}
            />
          </Form.Item>
          <Form.Item label="Task Color" name="taskColor">
            <ColorPicker value={colorHex} onChange={setColorHex} />
            <span style={{ display: "none" }}>
              {DOMPurify.sanitize(hexString)}
            </span>{" "}
            {/* Need span to display color in component, don't know why yet */}
          </Form.Item>
          <Form.Item>
            {action === "create" ? (
              ""
            ) : (
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            )}
          </Form.Item>
        </Form>
      )}
      {personTasks && personTasks.length > 0 ? (
        <PersonTaskList
          personTasks={personTasks}
          refetchTasks={refetchTasks}
          taskStatuses={taskStatuses}
          personId={personId}
          loggedUserId={loggedUserId}
          setEditMode={setEditMode}
          setRefetch={setRefetch}
        />
      ) : (
        "No Tasks"
      )}
    </Card>
  );
};

export default PersonTasks;
