import { Button, Form, Input, Segmented, Select, Space } from "antd";
import React, { useState } from "react";
import DOMPurify from "dompurify";
import { useMutation } from "@apollo/client";
import { v4 as uuidv4 } from "uuid";
import {
  CreatePersonCustomRelationsDocument,
  CreatePersonRelationsDocument,
  useGetRelationshipTypeQuery,
} from "../../../../generated/graphql";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";

interface PersonRelationshipFormProps {
  personName: string;
  people: any;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setRefetchRelations: React.Dispatch<React.SetStateAction<boolean>>;
  loggedUserId: string;
  personId: string;
  refetchCustomRelations?: any | undefined;
}

const PersonRelationshipForm: React.FC<PersonRelationshipFormProps> = ({
  personName,
  people,
  setEditMode,
  setRefetchRelations,
  loggedUserId,
  personId,
  refetchCustomRelations,
}) => {
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [defaultForm, setDefaultForm] = useState(true);
  const [createPersonRelations] = useMutation(CreatePersonRelationsDocument);
  const [createPersonCustomRelations] = useMutation(
    CreatePersonCustomRelationsDocument
  );
  const {
    data: relationshipType,
    loading: relationshipTypeLoading,
    error: relationshipTypeError,
  } = useGetRelationshipTypeQuery();
  const relationshipTypes = relationshipType?.__type?.enumValues;

  if (relationshipTypeLoading || relationshipTypeError) {
    return null; // ✅ Fix: Return `null` instead of `false`
  }

  const handleRelation = async () => {
    try {
      const values = await form.validateFields();
      const dateTime = new Date().toISOString();
      const connectPeople = () => {
        const list = [...values.toPerson];
        if (list.length) {
          return list.map((name) => ({
            where: {
              node: {
                personName: name,
                personCreatedBy: { userId: loggedUserId },
              },
            },
          }));
        }
      };

      const newRelation = await createPersonRelations({
        variables: {
          input: {
            relationId: uuidv4(),
            relationCreatedAt: dateTime,
            relationUpdatedAt: dateTime,
            relationType: values.relationshipStatus,
            fromPerson: {
              connect: {
                where: {
                  node: {
                    personId: personId,
                    personName: personName,
                    personCreatedBy: { userId: loggedUserId },
                  },
                },
              },
            },
            toPerson: {
              connect: connectPeople(),
            },
          },
        },
      });
      if (
        newRelation.data.createPersonRelations.personRelations[0].relationId
      ) {
        form.resetFields();
        setEditMode(false);
        setRefetchRelations(true);
      }
    } catch (error: any) {
      console.error("Failed to create relationship: ", error);
    }
  };

  const handleCustomRelation = async () => {
    try {
      const values = await form.validateFields();
      const dateTime = new Date().toISOString();
      const newCustomRelation = await createPersonCustomRelations({
        variables: {
          input: {
            customRelationId: uuidv4(),
            customRelationCreatedAt: dateTime,
            customRelationUpdatedAt: dateTime,
            customRelationTypeOne: values.typeOne,
            customRelationTypeTwo: values.typeTwo,
            personOne: {
              connect: {
                where: {
                  node: {
                    personId: personId,
                    personName: personName,
                    personCreatedBy: { userId: loggedUserId },
                  },
                },
              },
            },
            personTwo: {
              connect: {
                where: {
                  node: {
                    personName: values.personTwo,
                    personCreatedBy: { userId: loggedUserId },
                  },
                },
              },
            },
          },
        },
      });
      if (
        newCustomRelation.data.createPersonCustomRelations
          .personCustomRelations[0].customRelationId
      ) {
        form.resetFields();
        setErrorMessage("");
        setEditMode(false);
        refetchCustomRelations();
      }
    } catch (error: any) {
      console.error("Failed to create custom relationship: ", error);
      setErrorMessage("Failed to create custom relationship");
    }
  };

  return (
    <Space style={{ width: "100%" }} direction="vertical">
      <Segmented
        options={["Default", "Custom"]}
        onChange={(value) => {
          if (value === "Default") {
            setDefaultForm(true);
          } else {
            setDefaultForm(false);
          }
        }}
        style={{ marginBottom: "1rem" }}
      ></Segmented>
      {defaultForm && !relationshipTypeLoading && !relationshipTypeError && (
        <Form form={form} layout="vertical">
          <Form.Item
            label={personName + " knows"}
            name="toPerson"
            rules={[{ required: true, message: "Select a person" }]}
          >
            <Select
              showSearch
              placeholder="Select people"
              onChange={() => setErrorMessage("")}
              options={people?.people
                ?.filter((person: any) => person.personName !== personName)
                .map((person: any) => ({
                  key: DOMPurify.sanitize(person.personId),
                  value: DOMPurify.sanitize(person.personName),
                  label: DOMPurify.sanitize(person.personName),
                }))}
              mode="multiple"
            />
          </Form.Item>
          <Form.Item
            label="Type of Relationship"
            name="relationshipStatus"
            rules={[
              { required: true, message: "Type of Relationship is required" },
            ]}
          >
            <Select
              placeholder="Select a relationship type"
              onChange={() => setErrorMessage("")}
            >
              {relationshipTypes &&
                relationshipTypes?.map((status: any) => (
                  <Select.Option
                    key={DOMPurify.sanitize(status.name)}
                    value={DOMPurify.sanitize(status.name)}
                  >
                    {DOMPurify.sanitize(status.name.replace(/_/g, " "))}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Button type="primary" onClick={handleRelation}>
                  Save
                </Button>
              </div>
            </div>
          </Form.Item>
          {errorMessage && (
            <p
              style={{ textAlign: "center", color: "red", fontWeight: "bold" }}
            >
              {DOMPurify.sanitize(errorMessage)}
            </p>
          )}
        </Form>
      )}
      {!defaultForm && (
        <Form form={form} layout="vertical">
          <Form.Item
            label="Person 1"
            name="typeOne"
            rules={[
              { required: true, message: "Missing person and relationship" },
            ]}
          >
            <Input
              placeholder="Type of relationship"
              addonBefore={
                <Form.Item name="personOne" noStyle>
                  <Select
                    showSearch
                    placeholder={DOMPurify.sanitize(personName)}
                    onChange={() => setErrorMessage("")}
                    disabled={true}
                    options={[]}
                  />
                </Form.Item>
              }
            />
          </Form.Item>
          <div style={{ textAlign: "center" }}>
            <ArrowDownOutlined />
            <ArrowUpOutlined />
          </div>
          <Form.Item
            label="Person 2"
            name="typeTwo"
            rules={[
              { required: true, message: "Missing person and relationship" },
            ]}
          >
            <Input
              placeholder="Type of relationship"
              addonBefore={
                <Form.Item name="personTwo" noStyle>
                  <Select
                    showSearch
                    placeholder="Select a person"
                    onChange={() => setErrorMessage("")}
                    options={people?.people?.map((person: any) => ({
                      key: person.personId,
                      value: person.personName,
                      label: DOMPurify.sanitize(person.personName),
                    }))}
                  />
                </Form.Item>
              }
            />
          </Form.Item>
          <Form.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Button type="primary" onClick={handleCustomRelation}>
                  Save
                </Button>
              </div>
            </div>
          </Form.Item>
          {errorMessage && (
            <p
              style={{ textAlign: "center", color: "red", fontWeight: "bold" }}
            >
              {DOMPurify.sanitize(errorMessage)}
            </p>
          )}
        </Form>
      )}
    </Space>
  );
};

export default PersonRelationshipForm;
