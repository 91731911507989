import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { getCookie, removeCookie } from "./components/hooks/cookie";

/**
 * Apollo Client
 * @description Handles both file uploads and standard GraphQL requests
 */

const uploadLink = createUploadLink({
  uri: "https://brijmore-api-ehdwdba8ffdfcchw.eastus-01.azurewebsites.net/graphql",
  headers: {
    "Apollo-Require-Preflight": "true", // Required for Apollo file uploads
  },
});

const authLink = setContext((_, { headers }) => {
  const token = getCookie("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`)
    );
  if (networkError && networkError.message.includes("401")) {
    window.location.href = "/login";
    removeCookie("token");
    removeCookie("user");
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, uploadLink]),
  cache: new InMemoryCache(),
});

export default client;
