import { Button, Card, List } from "antd";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ApiOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  SortDirection,
  useGetPeopleQuery,
} from "../../../../generated/graphql";
import DOMPurify from "dompurify";
import PersonCustomRelationships from "./PersonCustomRelationships";
import PersonRelationshipForm from "./PersonRelationshipForm";

interface PersonRelationshipsProps {
  loggedUserId: string;
  personId: string;
  personName?: string;
  personRelationships?: any[] | undefined;
  personCustomRelationships?: any[] | undefined;
  setRefetchRelations: React.Dispatch<React.SetStateAction<boolean>>;
  refetchCustomRelations?: any | undefined;
}

const PersonRelationships: React.FC<PersonRelationshipsProps> = ({
  loggedUserId,
  personId,
  personName,
  personRelationships,
  personCustomRelationships,
  setRefetchRelations,
  refetchCustomRelations,
}) => {
  const { action } = useParams();
  const [editMode, setEditMode] = useState(false);

  const displayRelationList = () => {
    if (personRelationships?.length) {
      const groupedRelations = personRelationships.reduce(
        (acc: { type: string; peopleList: any[] }[], relation) => {
          // Destructure relevant properties
          const { relationType, toPerson, fromPerson } = relation;

          // Check if relationType group already exists
          const existingGroup = acc.find(
            (group) => group.type === relationType
          );

          // Determine which value to add (fromPerson or toPerson)
          const valueToAdd = toPerson.some(
            (person: { personId: string }) => person.personId === personId
          )
            ? fromPerson
            : toPerson;

          if (existingGroup) {
            // Add to the existing group and flatten the array
            existingGroup.peopleList.push(
              ...(Array.isArray(valueToAdd) ? valueToAdd : [valueToAdd])
            );
          } else {
            // Create a new group for this relationType
            acc.push({
              type: relationType,
              peopleList: Array.isArray(valueToAdd)
                ? [...valueToAdd]
                : [valueToAdd],
            });
          }

          return acc;
        },
        []
      );

      // Remove duplicates from the peopleList of each group
      const deduplicatedRelations = groupedRelations.map((group) => {
        const uniquePeople = Array.from(
          new Map(
            group.peopleList.map((person) => [person.personId, person])
          ).values()
        );

        return {
          ...group,
          peopleList: uniquePeople,
        };
      });

      return deduplicatedRelations;
    }

    return [];
  };

  const { data: people } = useGetPeopleQuery({
    variables: {
      where: {
        personCreatedBy: {
          userId: loggedUserId,
        },
      },
      options: { sort: [{ personName: SortDirection.Asc }] },
    },
  });

  return (
    <Card
      title={
        <>
          <ApiOutlined /> Relationships
        </>
      }
      className="relationship-card card-container"
      extra={
        action !== "create" && (
          <Button type="link" onClick={() => setEditMode(!editMode)}>
            {editMode ? (
              "Cancel"
            ) : (
              <>
                <PlusCircleOutlined /> Add
              </>
            )}
          </Button>
        )
      }
    >
      {editMode && (
        <PersonRelationshipForm
          personName={personName || ""}
          people={people}
          setEditMode={setEditMode}
          setRefetchRelations={setRefetchRelations}
          loggedUserId={loggedUserId}
          personId={personId}
          refetchCustomRelations={refetchCustomRelations}
        />
      )}
      <ul
        className="relationship-list"
        style={{ display: "flex", flexWrap: "wrap" }}
      >
        {displayRelationList().length
          ? displayRelationList().map((relation) => (
              <li className="relationship-item">
                <Card
                  style={{
                    width: "160px",
                    margin: "8px",
                    backgroundColor: "#f0f5ff",
                  }}
                >
                  <h3>{DOMPurify.sanitize(relation.type)}</h3>
                  <List
                    className="list"
                    itemLayout="vertical"
                    dataSource={relation.peopleList}
                    renderItem={(person) => (
                      <List.Item>
                        <Link
                          to={`/person/${DOMPurify.sanitize(
                            person.personName
                          )}`}
                          state={{ personId: person.personId }}
                          key={person.personId}
                        >
                          {DOMPurify.sanitize(person.personName)}
                        </Link>
                      </List.Item>
                    )}
                  />
                </Card>
              </li>
            ))
          : "No Relationships"}
      </ul>
      <PersonCustomRelationships
        customRelations={personCustomRelationships}
        refetchCustomRelations={refetchCustomRelations}
      />
    </Card>
  );
};

export default PersonRelationships;
