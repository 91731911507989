import { Button, Checkbox, Modal } from "antd";
import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";

const CheckColumns = ({ columns, setSelectedColumns }: any) => {
  const [loaded, setLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [checkedList, setCheckedList] = useState<string[]>(
    columns
      .slice(1)
      .filter((column: any) => !column.hidden)
      .map((column: any) => String(column.key))
  );
  const options = columns.slice(1).map((column: any) => ({
    label: DOMPurify.sanitize(
      String(column.key)
        .replace(/person/gi, "")
        .replace(/Primary/gi, "")
    ),
    value: DOMPurify.sanitize(String(column.key)),
  }));

  const isIndeterminate =
    checkedList.length > 0 && checkedList.length < options.length;

  useEffect(() => {
    if (!loaded) {
      setSelectedColumns([...columns]);
      setLoaded(true);
    }
  }, [loaded]);

  return (
    <div className="check-columns">
      <Button onClick={() => setOpen(true)}>Manage Columns</Button>
      <Modal
        title="Select columns to display"
        open={open}
        onCancel={() => setOpen(false)}
        footer={[
          <Button key="submit" type="primary" onClick={() => setOpen(false)}>
            OK
          </Button>,
        ]}
      >
        <Checkbox
          style={{
            width: "100%",
            padding: "8px 4px",
            margin: "8px 0px",
            borderBottom: "1px solid grey",
          }}
          indeterminate={isIndeterminate}
          onChange={(e) => {
            const checked = e.target.checked;
            if (checked) {
              setCheckedList(options.map((option: any) => option.value));
              setSelectedColumns([
                columns[0], // Include the first column: person name
                ...columns.slice(1).map((item: any) => ({
                  ...item,
                  hidden: false,
                })),
              ]);
            } else {
              setCheckedList([]);
              setSelectedColumns([
                columns[0], // Include the first column: person name
                ...columns.slice(1).map((item: any) => ({
                  ...item,
                  hidden: true,
                })),
              ]);
            }
          }}
        >
          Select All
        </Checkbox>
        <Checkbox.Group
          value={checkedList}
          onChange={(value) => {
            const sanitizedValue = (value as string[]).map((val) =>
              DOMPurify.sanitize(val)
            );
            setCheckedList(sanitizedValue);
            setSelectedColumns([
              columns[0], // Include the first column: person name
              ...columns.slice(1).map((item: any) => ({
                ...item,
                hidden: !sanitizedValue.includes(item.key as string),
              })),
            ]);
          }}
        >
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {options.map((item: any, index: number) => (
              <div
                key={index}
                style={{
                  flex: "1 0 50%", // 50% width for two columns
                  maxWidth: "50%",
                  boxSizing: "border-box",
                  padding: "4px",
                }}
              >
                <Checkbox value={item.value}>
                  {DOMPurify.sanitize(item.label)}
                </Checkbox>
              </div>
            ))}
          </div>
        </Checkbox.Group>
      </Modal>
    </div>
  );
};

export default CheckColumns;
