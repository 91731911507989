import {
  Badge,
  Button,
  Input,
  InputRef,
  Select,
  Space,
  Table,
  TableColumnType,
  Tag,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { getCookie } from "../../hooks/cookie";
import {
  SortDirection,
  Task,
  UpdateTasksDocument,
  useGetPersonTasksQuery,
  useGetTaskStatusQuery,
} from "../../../generated/graphql";
import { useNavigate } from "react-router-dom";
import { SearchOutlined, StarFilled } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import dayjs from "dayjs";
import DOMPurify from "dompurify";
import "../../../style/tasks-list.scss";

type DataIndex = keyof Task;

const TaskList = () => {
  const navigate = useNavigate();
  const userCookie = getCookie("user");
  const userObj = userCookie
    ? JSON.parse(decodeURIComponent(userCookie))
    : null;
  const loggedUserId = userObj ? DOMPurify.sanitize(userObj.userId) : null;

  const { data: tasks, refetch: refetchTasks } = useGetPersonTasksQuery({
    variables: {
      where: {
        taskOwner: { userId: loggedUserId },
      },
    },
  });

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const { data: taskStatus } = useGetTaskStatusQuery();
  const taskStatuses = taskStatus?.__type?.enumValues?.map((status) => ({
    name: DOMPurify.sanitize(status.name),
  }));

  const [updateTask] = useMutation(UpdateTasksDocument);

  useEffect(() => {
    refetchTasks();
  }, [tasks]);

  const findTask = (taskId: string) => {
    return tasks?.tasks?.find((task) => task.taskId === taskId);
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: () => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<Task> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          {dataIndex === "taskStatus" ? (
            <Select
              onChange={(value: string) => {
                setSelectedKeys([DOMPurify.sanitize(value)]);
              }}
              options={taskStatuses?.map((status) => ({
                label: status.name.replace(/_/g, " "),
                value: status.name,
              }))}
              style={{ width: "100%", marginBottom: "8px" }}
            />
          ) : (
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => {
                const value = e.target.value;
                setSelectedKeys([DOMPurify.sanitize(value)]);
              }}
              onPressEnter={() =>
                handleSearch(selectedKeys as string[], confirm, dataIndex)
              }
              style={{ marginBottom: 8, display: "block" }}
            />
          )}
          <Space>
            <Button
              type="primary"
              onClick={() =>
                handleSearch(selectedKeys as string[], confirm, dataIndex)
              }
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                if (clearFilters) {
                  clearFilters();
                  setSearchText("");
                  setSelectedKeys([]);
                  confirm();
                }
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      return (
        record[dataIndex]
          ?.toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()) ?? false
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const columns: TableColumnType<any>[] = [
    {
      title: "Priority",
      width: 50,
      dataIndex: "taskFavorite",
      key: "taskFavorite",
      sorter: (a, b) => Number(b.taskFavorite) - Number(a.taskFavorite),
      defaultSortOrder: "ascend",
      render(value, record) {
        return record.taskFavorite ? (
          <StarFilled style={{ color: "#ffc53d", fontSize: "24px" }} />
        ) : null;
      },
    },
    {
      title: "Task",
      dataIndex: "taskName",
      key: "taskName",
      ...(getColumnSearchProps("taskName") as any),
      render(value, record) {
        return (
          <div>
            <Badge
              color={DOMPurify.sanitize(record.taskColor || "#1677ff")}
              text={DOMPurify.sanitize(value)}
              style={{ fontWeight: "bold" }}
            />
            <p style={{ fontSize: "12px", color: "#777" }}>
              {DOMPurify.sanitize(record.taskDescription || "")}
            </p>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "taskStatus",
      key: "taskStatus",
      ...(getColumnSearchProps("taskStatus") as any),
      render(value, record) {
        return (
          <div className="non-clickable-column">
            {taskStatuses?.map((status) => (
              <Tag.CheckableTag
                style={{ position: "relative", zIndex: 10 }}
                key={`${record.taskId}-${status.name}`}
                checked={
                  findTask(record.taskId)?.taskStatus === status.name &&
                  findTask(record.taskId)?.taskId === record.taskId
                }
                onChange={async (checked) => {
                  if (checked) {
                    await updateTask({
                      variables: {
                        update: {
                          taskStatus: status.name,
                          taskUpdatedAt: dayjs().format("YYYY-MM-DD"),
                        },
                        where: {
                          taskId: record.taskId,
                          taskPerson_SINGLE: {
                            personId: record.taskPerson[0].personId,
                          },
                          taskOwner: { userId: loggedUserId },
                        },
                      },
                    }).then(() => refetchTasks());
                  }
                }}
              >
                {status.name.replace(/_/g, " ")}
              </Tag.CheckableTag>
            ))}
          </div>
        );
      },
      sorter: (a, b) =>
        DOMPurify.sanitize(a.taskStatus).localeCompare(
          DOMPurify.sanitize(b.taskStatus)
        ),
    },
    {
      title: "Due Date",
      dataIndex: "taskDueDate",
      key: "taskDueDate",
      sorter: (a, b) =>
        new Date(a.taskDueDate).getTime() - new Date(b.taskDueDate).getTime(),
    },
    {
      title: "Person",
      dataIndex: "taskPerson",
      key: "taskPerson",
      render(value, record) {
        return DOMPurify.sanitize(value[0]?.personName || "Unknown");
      },
      sorter: (a, b) =>
        DOMPurify.sanitize(a.taskPerson[0]?.personName).localeCompare(
          DOMPurify.sanitize(b.taskPerson[0]?.personName)
        ),
    },
  ];

  return (
    <div className="tasks-list">
      <Table
        columns={columns}
        dataSource={tasks?.tasks}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default TaskList;
