import {
  Button,
  Card,
  Modal,
  Checkbox,
  Tooltip,
  Spin,
  Select,
  Switch,
} from "antd";
import React, { useEffect, useState } from "react";
import { renderPhone } from "../../../hooks/formats";
import { Link, useNavigate } from "react-router-dom";
import { tagColor } from "../../../hooks/visual";
import { useSelectedSpheres } from "../../../../contexts/SelectedSpheresContext";
import { EyeOutlined, HeartFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import InfiniteScroll from "react-infinite-scroll-component";
import { SortDirection } from "../../../../generated/graphql";
import "../../../../style/responsive-list.scss";
import DOMPurify from "dompurify";

interface ResponsiveListProps {
  list: any[];
  openFilterModal?: boolean;
  setOpenFilterModal: (open: boolean) => void;
  sortOrder: any;
  setSortOrder: (sortOrder: any) => void;
}

const ResponsiveList: React.FC<ResponsiveListProps> = ({
  list,
  openFilterModal,
  setOpenFilterModal,
  sortOrder,
  setSortOrder,
}) => {
  const navigate = useNavigate();
  const { Option } = Select;
  const { addSphere } = useSelectedSpheres();
  const [currentPage, setCurrentPage] = useState(1);
  const [loadedList, setLoadedList] = useState<any[]>(list.slice(0, 2));
  const [hasMore, setHasMore] = useState(true);
  const [sortBy, setSortBy] = useState<string>("personName");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [displayKeys, setDisplayKeys] = useState<Record<string, boolean>>({});
  const [loadInitialkeys, setLoadInitialKeys] = useState(true);

  useEffect(() => {
    setSortOrder({
      [sortBy]:
        sortDirection === "asc" ? SortDirection.Asc : SortDirection.Desc,
    });
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (list.length && loadInitialkeys) {
      const keys = getResponsiveList({ person: list[0] }).reduce(
        (acc: Record<string, boolean>, item) => {
          acc[item.key] = item.display;
          return acc;
        },
        {}
      );
      setDisplayKeys(keys);
      setLoadInitialKeys(false);
    }
  }, [list, loadInitialkeys]);

  const loadMoreData = () => {
    const nextPageItems = list.slice(currentPage * 2, (currentPage + 1) * 2);
    if (nextPageItems.length > 0) {
      setLoadedList((prev) => [...prev, ...nextPageItems]);
      setCurrentPage((prev) => prev + 1);
    } else {
      setHasMore(false);
    }
  };

  const handleAddSphere = (sphere: any) => {
    addSphere(sphere);
  };

  const handleNavigate = (personId: string, personName: string) => {
    navigate(`/person/${personName}`, {
      state: { personId: personId },
    });
  };

  const getResponsiveList = ({ person }: { person: any }) => [
    {
      key: "personPrimaryEmail",
      content: <p>Email: {person.personPrimaryEmail}</p>,
      display: true,
    },
    {
      key: "personPrimaryPhone",
      content: <p>Phone: {renderPhone(person.personPrimaryPhone)}</p>,
      display: true,
    },
    {
      key: "personSpheres",
      content: person.personSpheres.length > 0 && (
        <>
          {" "}
          <p>Spheres: </p>
          <ul className="sphere-list">
            {person.personSpheres.map((sphere: any) => (
              <li key={sphere.sphereId} className="sphere-li">
                <Link
                  to={"/people-list"}
                  key={sphere.sphereId}
                  onClick={() => handleAddSphere(sphere)}
                >
                  <span
                    className="tag"
                    style={tagColor(
                      sphere.sphereColor ? sphere.sphereColor : "#1677ff"
                    )}
                  >
                    {sphere.sphereName}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </>
      ),
      display: true,
    },
    {
      key: "personPlatforms",
      content:
        person.personPlatforms.length > 0 ? (
          <div>
            <p>Platforms: </p>
            {person.personPlatforms.map((platform: any) => {
              return (
                <Link
                  to={platform.platformUrl ? platform.platformUrl : "#"}
                  target="_blank"
                  key={platform.platformId}
                >
                  <Button type="default">
                    <Tooltip title="Open link">
                      {DOMPurify.sanitize(platform.platformName)}
                    </Tooltip>
                    {platform.platformFavorite ? (
                      <Tooltip title="Preferred way of communication">
                        <HeartFilled
                          style={{
                            color: "#ff5d8f",
                            fontSize: "20px",
                            paddingLeft: "8px",
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <></>
                    )}
                  </Button>
                </Link>
              );
            })}
          </div>
        ) : (
          ""
        ),
      display: false,
    },
    {
      key: "personPhones",
      content: person.personPhones ? (
        <div>
          <p>Other Phones: </p>
          {person.personPhones.map((phone: any) => {
            return (
              <Tooltip title="Call">
                {DOMPurify.sanitize(phone.phoneType)}{" "}
                {renderPhone(DOMPurify.sanitize(phone.phoneNumber))}
              </Tooltip>
            );
          })}
        </div>
      ) : (
        ""
      ),
      display: false,
    },
    {
      key: "personEmails",
      content: person.personEmails ? (
        <div>
          <p>Other Emails: </p>
          {person.personEmails.map((email: any) => {
            return (
              <p>
                {DOMPurify.sanitize(email.emailType)}{" "}
                {DOMPurify.sanitize(email.emailAddress)}
              </p>
            );
          })}
        </div>
      ) : (
        ""
      ),
      display: false,
    },
    {
      key: "personBirthDate",
      content: person.personBirthDate ? (
        <div>
          <strong>Birthdate:</strong>
          <span>
            {dayjs(DOMPurify.sanitize(person?.personBirthDate)).format(
              "MM/DD/YYYY"
            )}
          </span>
        </div>
      ) : (
        ""
      ),
      display: false,
    },
  ];

  const handleCheckboxChange = (key: string, checked: boolean) => {
    setDisplayKeys((prev) => ({ ...prev, [key]: checked }));
  };

  return (
    <InfiniteScroll
      dataLength={loadedList.length}
      next={loadMoreData}
      hasMore={hasMore}
      loader={
        <Spin tip="Loading" size="small">
          Loading...
        </Spin>
      }
      endMessage={<p>No more items to load</p>}
      className="responsive-list-container"
    >
      <ul className="responsive-list">
        {list.map((person) => (
          <li key={person.personId} className="li-container">
            <Card
              title={DOMPurify.sanitize(person.personName)}
              extra={<EyeOutlined />}
              className="card"
              onClick={() => handleNavigate(person.personId, person.personName)}
            >
              {getResponsiveList({ person })
                .filter((item) => displayKeys[item.key])
                .map((item) => item.content)}
            </Card>
          </li>
        ))}
      </ul>
      <Modal
        title="Filters"
        open={openFilterModal}
        onCancel={() => setOpenFilterModal(false)}
        footer={null}
        className="filter-modal"
      >
        <div className="checkbox-container select-all">
          <p>
            <b>Select data to display</b>
          </p>
          <Checkbox
            checked={Object.values(displayKeys).every((checked) => checked)}
            indeterminate={
              Object.values(displayKeys).some((checked) => checked) &&
              !Object.values(displayKeys).every((checked) => checked)
            }
            onChange={(e) => {
              const isChecked = e.target.checked;
              setDisplayKeys((prev) =>
                Object.keys(prev).reduce(
                  (acc, key) => ({ ...acc, [key]: isChecked }),
                  {}
                )
              );
            }}
            className={`filter-checkbox ${
              Object.values(displayKeys).every((checked) => checked)
                ? "checked"
                : ""
            }`}
          >
            Select All
          </Checkbox>
        </div>
        <div className="checkbox-container">
          {Object.keys(displayKeys).map((key) => (
            <Checkbox
              key={key}
              checked={displayKeys[key]}
              onChange={(e) => handleCheckboxChange(key, e.target.checked)}
              className={`filter-checkbox ${displayKeys[key] ? "checked" : ""}`}
            >
              {key
                .replace(/person/g, "")
                .replace(/([A-Z])/g, " $1")
                .trim()}{" "}
            </Checkbox>
          ))}
        </div>
        <div className="sort-controls" style={{ marginBottom: "16px" }}>
          <p>
            <b>Sort by</b>
          </p>
          <Select
            defaultValue={sortBy}
            style={{ width: 200, marginRight: 16 }}
            onChange={(value) => {
              setSortBy(value);
            }}
          >
            <Option value="personUpdatedAt">Last Updated</Option>
            <Option value="personName">Name</Option>
            <Option value="personPrimaryEmail">Email</Option>
            <Option value="personPrimaryPhone">Phone</Option>
            <Option value="personBirthDate">Birthdate</Option>
          </Select>
          <Switch
            checked={sortDirection === "asc"}
            onChange={(checked) => setSortDirection(checked ? "asc" : "desc")}
            checkedChildren="Asc"
            unCheckedChildren="Desc"
          />
        </div>
      </Modal>
    </InfiniteScroll>
  );
};

export default ResponsiveList;
