import { useMutation } from "@apollo/client";
import {
  Form,
  Input,
  Select,
  Button,
  Card,
  Modal,
  DatePicker,
  ColorPicker,
  DatePickerProps,
  Checkbox,
} from "antd";
import dayjs from "dayjs";
import {
  CreateTasksDocument,
  SortDirection,
  useGetPeopleQuery,
  useGetTaskStatusQuery,
} from "../../../generated/graphql";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useMemo, useState } from "react";
import { Color } from "antd/es/color-picker";
import { StarFilled, StarOutlined } from "@ant-design/icons";
import DOMPurify from "dompurify";
import "../../../style/task-form.scss";

interface TaskFormProps {
  visible: boolean;
  loggedUserId: string;
  onCancel: (success: boolean) => void;
  selectedDueDate?: string;
}

const TaskForm: React.FC<TaskFormProps> = ({
  visible,
  loggedUserId,
  onCancel,
  selectedDueDate,
}) => {
  const [form] = Form.useForm();
  const [personValues, setPersonValues] = useState<any>();
  const [createTasks] = useMutation(CreateTasksDocument);
  const { data: taskStatus } = useGetTaskStatusQuery();
  const taskStatuses = taskStatus?.__type?.enumValues?.map((status: any) => ({
    name: DOMPurify.sanitize(status.name),
  }));

  const { data: people } = useGetPeopleQuery({
    variables: {
      where: {
        personCreatedBy: {
          userId: DOMPurify.sanitize(loggedUserId),
        },
      },
      options: { sort: [{ personName: SortDirection.Asc }] },
    },
  });

  const [colorHex, setColorHex] = useState<string | Color>("#1677ff");
  const [dueDate, setDueDate] = useState<string | null | undefined>(null);
  const [favorite, setFavorite] = useState<boolean>(false);

  useEffect(() => {
    if (selectedDueDate) {
      form.setFieldsValue({
        taskDueDate: dayjs(selectedDueDate, "YYYY-MM-DD"),
      });
    }
  }, [selectedDueDate]);

  const hexString = useMemo<string>(
    () => (typeof colorHex === "string" ? colorHex : colorHex?.toHexString()),
    [colorHex]
  );

  const onDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    if (typeof dateString === "string" && dateString.length > 0) {
      setDueDate(dateString);
    }
  };

  const handleFormSubmit = async (values: any) => {
    const personId = DOMPurify.sanitize(personValues?.key);
    const personName = DOMPurify.sanitize(personValues?.value);

    try {
      const createData = {
        ...values,
        taskId: uuidv4(),
        taskCreatedAt: dayjs().format("YYYY-MM-DD"),
        taskUpdatedAt: dayjs().format("YYYY-MM-DD"),
        taskOwner: {
          connect: {
            where: {
              node: {
                userId: DOMPurify.sanitize(loggedUserId),
              },
            },
          },
        },
        taskPerson: {
          connect: {
            where: {
              node: {
                personId: personId,
                personName: personName,
              },
            },
          },
        },
      };

      await createTasks({
        variables: {
          input: {
            ...createData,
            taskDueDate: dueDate
              ? dueDate
              : selectedDueDate
              ? selectedDueDate
              : undefined,
            taskColor: DOMPurify.sanitize(hexString ? hexString : "#1677ff"),
            taskFavorite: favorite,
          },
        },
      }).then((res) => {
        if (!res.errors) {
          form.resetFields();
          onCancel(true);
        }
      });
    } catch (error) {
      console.error("Error creating a task", error);
    }
  };

  return (
    <Modal
      title="Add Task"
      open={visible}
      footer={null}
      onCancel={() => onCancel(false)}
      className="task-form"
    >
      <Card style={{ maxWidth: "800px", width: "100%" }}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFormSubmit}
          initialValues={{
            taskName: "",
            taskDescription: "",
            taskStatus: "PENDING",
            taskDueDate: selectedDueDate
              ? dayjs(selectedDueDate, "YYYY-MM-DD")
              : undefined,
          }}
        >
          <Form.Item
            label="Person"
            name="taskPerson"
            rules={[{ required: true, message: "Select a person" }]}
          >
            <Select
              showSearch
              placeholder="Select people"
              onSelect={(value, option) => setPersonValues(option)}
              options={people?.people?.map((person) => ({
                key: DOMPurify.sanitize(person.personId),
                value: DOMPurify.sanitize(person.personName),
                label: DOMPurify.sanitize(person.personName),
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Mark as Priority :"
            name="taskFavorite"
            className="favorite-checkbox"
          >
            <Checkbox
              className="platform-checkbox"
              onChange={(e) => {
                setFavorite(e.target.checked);
              }}
            >
              {favorite ? (
                <StarFilled
                  style={{
                    color: "#ffc53d",
                    fontSize: "32px",
                  }}
                />
              ) : (
                <StarOutlined
                  style={{
                    color: "#ffc53d",
                    fontSize: "32px",
                  }}
                />
              )}
            </Checkbox>
          </Form.Item>
          <Form.Item
            label="Task Name"
            name="taskName"
            rules={[{ required: true, message: "Task Name is required" }]}
          >
            <Input
              placeholder="Enter task name"
              onChange={(e) =>
                form.setFieldsValue({
                  taskName: DOMPurify.sanitize(e.target.value),
                })
              }
            />
          </Form.Item>
          <Form.Item label="Task Description" name="taskDescription">
            <Input.TextArea
              placeholder="Enter task description"
              onChange={(e) =>
                form.setFieldsValue({
                  taskDescription: DOMPurify.sanitize(e.target.value),
                })
              }
            />
          </Form.Item>
          <Form.Item
            label="Task Status"
            name="taskStatus"
            rules={[{ required: true, message: "Task Status is required" }]}
          >
            <Select placeholder="Select a task status">
              {taskStatuses &&
                taskStatuses.map((status: any) => (
                  <Select.Option key={status.name} value={status.name}>
                    {DOMPurify.sanitize(status.name.replace(/_/g, " "))}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="Task Due Date" name="taskDueDate">
            <DatePicker
              style={{ width: "100%" }}
              placeholder="Select due date"
              onChange={onDateChange}
            />
          </Form.Item>
          <Form.Item label="Task Color" name="taskColor">
            <ColorPicker value={colorHex} onChange={setColorHex} />
            <span style={{ display: "none" }}>
              {DOMPurify.sanitize(hexString)}
            </span>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Modal>
  );
};

export default TaskForm;
