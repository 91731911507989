import React, { useEffect, useState } from "react";
import { Table, Alert, Button, Space, Input, TableColumnType } from "antd";
import { SortDirection, useGetPeopleQuery } from "../../../generated/graphql";
import { useLocation, useNavigate } from "react-router-dom";
import { getCookie } from "../../hooks/cookie";
import { multiColorGradient, oneColorGradient } from "../../hooks/visual";
import CheckColumns from "./list-components/CheckColumns";
import useColumns from "./list-components/columns";
import { AppstoreOutlined } from "@ant-design/icons";
import "../../../style/list-page.scss";
import ResponsiveList from "./list-components/ResponsiveList";

/**
 * Component to display list of people related to logged user and selected spheres.
 * Columns are listed in columns.tsx.
 * Button to select columns to display -> CheckColumns.
 * Button to create person -> CreatePersonForm, click on a row to view person profile -> DisplayPerson.
 * Create person includes selected spheres by default.
 */
const PeopleList: React.FC = () => {
  const navigate = useNavigate();
  const [globalSearchText, setGlobalSearchText] = useState("");
  const columns = useColumns();
  const [selectedColumns, setSelectedColumns] = useState<
    TableColumnType<any>[]
  >([]);
  let { state } = useLocation();
  let sphereIds = state
    ? state?.selectedSpheres.map((sphere: any) => sphere.sphereId)
    : [];
  let sphereNames = state
    ? state?.selectedSpheres.map((sphere: any) => sphere.sphereName)
    : [];
  const userCookie = getCookie("user");
  const userObj = userCookie
    ? JSON.parse(decodeURIComponent(userCookie))
    : null;
  const loggedUserId = userObj ? userObj.userId : null;
  const [sortOrder, setSortOrder] = useState<any>({
    personUpdatedAt: SortDirection.Desc,
  });
  const {
    loading,
    error,
    data = { people: [] },
    refetch,
  } = useGetPeopleQuery(
    sphereIds.length > 0
      ? {
          variables: {
            where: {
              personSpheres_SINGLE: {
                sphereId_IN: sphereIds,
                sphereUserOwner: { userId: loggedUserId },
              },
            },
            options: { sort: [sortOrder] },
          },
        }
      : {
          variables: {
            where: {
              personCreatedBy: {
                userId: loggedUserId,
              },
            },
            options: { sort: [sortOrder] },
          },
        }
  );
  const [openFilterModal, setOpenFilterModal] = useState(false);

  /**Refresh list data when fetch is true or sphereIds/sphereNames change */
  useEffect(() => {
    if (state) {
      refetch();
    }
  }, [state, data]);

  if (error)
    return (
      <Alert
        message="Error"
        description={error.message}
        type="error"
        showIcon
      />
    );

  const dataSource: any = data?.people
    .filter(
      (person) =>
        person.personName &&
        person.personName.toLowerCase().includes(globalSearchText.toLowerCase())
    )
    .map((person, index) => ({
      key: index,
      personId: person.personId,
      personName: person.personName,
      personPrimaryEmail: person.personPrimaryEmail,
      personSpheres: person.personSpheres,
      personPrimaryPhone: person.personPrimaryPhone,
      personPlatforms: person.personPlatforms,
      personPhones: person.personPhones,
      personEmails: person.personEmails,
      personBirthDate: person.personBirthDate,
    }));

  const handleGradient = () => {
    if (state?.selectedSpheres.length === 1) {
      return oneColorGradient(
        state?.selectedSpheres[0].sphereColor
          ? state?.selectedSpheres[0].sphereColor
          : "#1677ff"
      );
    } else {
      return multiColorGradient(
        state?.selectedSpheres.map((sphere: any) =>
          sphere.sphereColor ? sphere.sphereColor : "#1677ff"
        )
      );
    }
  };

  return (
    <>
      <div
        className="page-title-header"
        style={{
          background: sphereNames.length > 0 ? handleGradient() : undefined,
        }}
      >
        <h1
          style={{
            backgroundColor: "white",
            width: "fit-content",
            padding: "16px 16px",
            borderRadius: "8px",
            color: "#141414",
            opacity: 0.8,
          }}
        >
          {sphereNames.length > 0
            ? sphereNames
                .map((sphereName: string) => sphereName.toUpperCase())
                .join(" + ")
            : "PEOPLE".toUpperCase()}
        </h1>
      </div>
      <Space className="list-page-btn-header desktop-only">
        <CheckColumns
          columns={columns}
          setSelectedColumns={setSelectedColumns}
        />
        <Input.Search
          placeholder="Search by name"
          onSearch={(value) => setGlobalSearchText(value)}
          allowClear={true}
        />
      </Space>
      <Space className="list-page-btn-header responsive-only">
        <Input.Search
          placeholder="Search by name"
          onSearch={(value) => setGlobalSearchText(value)}
          allowClear={true}
          className="input-search-responsive"
        />
        <Button
          className="filter-button"
          onClick={() => setOpenFilterModal(true)}
        >
          <AppstoreOutlined />
        </Button>
      </Space>
      <Table
        columns={
          selectedColumns && selectedColumns[0] ? selectedColumns : columns
        }
        dataSource={dataSource}
        pagination={{ pageSize: 10 }}
        onRow={(record) => ({
          onClick: () => {
            navigate(`/person/${record.personName}`, {
              state: { personId: record.personId },
            });
          },
        })}
        style={{ margin: "4px" }}
        className="list-page-table"
      />
      <ResponsiveList
        list={dataSource}
        openFilterModal={openFilterModal}
        setOpenFilterModal={setOpenFilterModal}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
      />
    </>
  );
};

export default PeopleList;
