import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { List, Space, Tag } from "antd";
import React, { useEffect } from "react";
import "../../../../style/customRelationship.scss";
import { Link, useLocation, useParams } from "react-router-dom";
import DOMPurify from "dompurify";

interface PersonCustomRelationshipsProps {
  customRelations: any[] | undefined;
  refetchCustomRelations?: any | undefined;
}

const PersonCustomRelationships: React.FC<PersonCustomRelationshipsProps> = ({
  customRelations,
  refetchCustomRelations,
}) => {
  const { state } = useLocation();
  const { name } = useParams();

  useEffect(() => {
    if (refetchCustomRelations && state) {
      refetchCustomRelations();
    }
  }, [refetchCustomRelations, state]);

  const isPersonOne = ({
    personName,
    personId,
  }: {
    personName: string;
    personId: string;
  }) => {
    console.log("personOne", personName, personId);
    console.log("name", name);
    console.log("personId", state.personId);
    console.log("same name", personName === name);
    console.log("same id", personId === state.personId);
    if (personName && personName === name && personId === state.personId) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Space className="custom-relationship">
      <List
        className="custom-relationship-list"
        itemLayout="horizontal"
        dataSource={customRelations}
        renderItem={(item) => (
          <List.Item className="custom-relationship-item">
            <p>
              <b>
                {isPersonOne({
                  personName: item.personOne.personName,
                  personId: item.personOne.personId,
                })
                  ? item.personOne.personName
                  : item.personTwo.personName}
              </b>
            </p>
            <Tag>
              {isPersonOne({
                personName: item.personOne.personName,
                personId: item.personOne.personId,
              })
                ? item.customRelationTypeOne
                : item.customRelationTypeTwo}
            </Tag>
            <div className="arrows">
              <ArrowRightOutlined />
              <ArrowLeftOutlined />
            </div>
            <Tag>
              {isPersonOne({
                personName: item.personOne.personName,
                personId: item.personOne.personId,
              })
                ? item.customRelationTypeTwo
                : item.customRelationTypeOne}
            </Tag>
            <b>
              <Link
                to={`/person/${DOMPurify.sanitize(
                  isPersonOne({
                    personName: item.personOne.personName,
                    personId: item.personOne.personId,
                  })
                    ? item.personTwo.personName
                    : item.personOne.personName
                )}`}
                state={{
                  personId: isPersonOne({
                    personName: item.personOne.personName,
                    personId: item.personOne.personId,
                  })
                    ? item.personTwo.personId
                    : item.personOne.personId,
                }}
                key={
                  isPersonOne({
                    personName: item.personOne.personName,
                    personId: item.personOne.personId,
                  })
                    ? item.personTwo.personId
                    : item.personOne.personId
                }
              >
                {DOMPurify.sanitize(
                  isPersonOne({
                    personName: item.personOne.personName,
                    personId: item.personOne.personId,
                  })
                    ? item.personTwo.personName
                    : item.personOne.personName
                )}
              </Link>
            </b>
          </List.Item>
        )}
      ></List>
    </Space>
  );
};

export default PersonCustomRelationships;
