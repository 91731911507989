import {
  EditOutlined,
  PhoneOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Button, Card, Form, List } from "antd";
import FormItem from "antd/es/form/FormItem";
import React, { useEffect } from "react";
import {
  UpdatePeopleDocument,
  UpdatePhonesDocument,
  useGetPhoneTypesQuery,
} from "../../../../generated/graphql";
import { useMutation } from "@apollo/client";
import { handlePhoneFormat, renderPhone } from "../../../hooks/formats";
import PhoneInput from "antd-phone-input";
import PhoneInputs from "../form-components/PhoneInputs";
import { PhoneInterface } from "../../../types/types";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";

interface PersonPhonesProps {
  personId: string;
  personPrimaryPhone: string;
  personPhones: PhoneInterface[];
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
  newPerson?: any;
  setNewPerson?: React.Dispatch<React.SetStateAction<any>>;
}
const PersonPhones: React.FC<PersonPhonesProps> = ({
  personId,
  personPrimaryPhone,
  personPhones,
  setRefetch,
  newPerson,
  setNewPerson,
}) => {
  const { action } = useParams();
  const [editMode, setEditMode] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [phones, setPhones] = React.useState<any>([]);
  const { data: phoneTypes } = useGetPhoneTypesQuery();
  const [updatePhone] = useMutation(UpdatePhonesDocument);
  const [updatePerson] = useMutation(UpdatePeopleDocument);

  useEffect(() => {
    setPhones(
      personPhones.map((phone: PhoneInterface) => ({
        phoneId: phone.phoneId,
        phoneType: phone.phoneType,
        phoneNumber: phone.phoneNumber,
        newPlatform: false,
      }))
    );
    if (!editMode && !personId.length) {
      setEditMode(true);
    }
  }, [editMode, personId, personPhones]);

  const handleSubmit = async (values: any) => {
    const dateTime = new Date().toISOString();
    const { personPhone, personPrimaryPhone, ...rest } = values;
    const phoneCleanList = phones.filter(
      (phone: PhoneInterface) =>
        phone.phoneNumber.length && phone.phoneType.length
    );
    try {
      const handleCreatePhones = () => {
        const list = [...phoneCleanList];
        if (list.length && list[0].phoneNumber) {
          const newPhones = list.filter((phone) => phone.newPhone);

          if (newPhones.length) {
            return newPhones.map((phone) => ({
              node: {
                phoneId: phone.phoneId,
                phoneType: phone.phoneType,
                phoneNumber: phone.phoneNumber,
                phoneOwner: {
                  connect: { where: { node: { personId: personId } } },
                },
              },
            }));
          }
        }
      };
      const handleUpdatePhones = async () => {
        try {
          const list = [...phoneCleanList];
          if (list.length && list[0].phoneNumber && personId) {
            const modifiedPhones = list.filter(
              (phone) => !phone.newPhone && phone.modified === "update"
            );
            if (modifiedPhones.length) {
              await Promise.all(
                modifiedPhones.map((phone) =>
                  updatePhone({
                    variables: {
                      where: {
                        phoneId: phone.phoneId,
                        phoneOwner: { personId: personId },
                      },
                      update: {
                        phoneType: phone.phoneType,
                        phoneNumber: phone.phoneNumber,
                      },
                    },
                  })
                )
              );
              setEditMode(false);
              setRefetch(true);
            } else {
              setEditMode(false);
              setRefetch(true);
            }
          } else {
            setEditMode(false);
            setRefetch(true);
          }
        } catch (error: any) {
          console.error("[Failed to update Phone]", error);
          if (error.graphQLErrors) {
            console.error(
              "[PERSON FORM - GraphQL Error: ]" +
                error.graphQLErrors.map((e: any) => e.message).join(", ")
            );
          }
          if (error.networkError) {
            console.error(
              "[PERSON FORM - Network Error: ]" + error.networkError.message
            );
          }
        }
      };
      const handleDeletePhones = () => {
        const list = [...phoneCleanList];
        if (list.length && list[0].phoneNumber && personId) {
          const deletedPhones = list.filter(
            (phone) => !phone.newPhone && phone.modified === "delete"
          );
          if (deletedPhones.length) {
          }

          return deletedPhones.map((phone) => ({
            where: {
              node: {
                phoneId: phone.phoneId,
                phoneOwner: { personId: personId },
              },
            },
          }));
        }
      };

      const response = await updatePerson({
        variables: {
          update: {
            personPrimaryPhone: `${personPhone.isoCode}.${personPhone.countryCode}.${personPhone.areaCode}.${personPhone.phoneNumber}`,
            personPhones: {
              create: handleCreatePhones(),
              delete: handleDeletePhones(),
            },
            personUpdatedAt: dateTime,
          },
          where: { personId: personId },
        },
      });

      if (response.errors) {
        setErrorMessage("ERROR TO UPDATE PERSON" + response.errors[0].message);
      } else {
        handleUpdatePhones();
      }
    } catch (e) {
      console.error("ERROR UPDATING PERSON'S PHONES: " + e);
    }
  };

  return (
    <Card
      className="card-children"
      title={
        <>
          <PhoneOutlined /> Phones
        </>
      }
      extra={
        <Button type="link" onClick={() => setEditMode(!editMode)}>
          {editMode ? personId !== "" ? "Cancel" : "" : <EditOutlined />}
        </Button>
      }
    >
      {editMode ? (
        <Form
          layout="vertical"
          initialValues={{
            personPhone: {
              countryCode: handlePhoneFormat(personPrimaryPhone).countryCode,
              areaCode: handlePhoneFormat(personPrimaryPhone).areaCode,
              isoCode: handlePhoneFormat(personPrimaryPhone).isoCode,
              phoneNumber: handlePhoneFormat(personPrimaryPhone).phoneNumber,
            },
          }}
          onFinish={handleSubmit}
        >
          <FormItem label="Primary Phone" name="personPhone" required={true}>
            <PhoneInput
              required={true}
              onChange={(e) => {
                if (action === "create" && setNewPerson) {
                  let personPhone = e;
                  setNewPerson({
                    ...newPerson,
                    personPrimaryPhone: `${personPhone.isoCode}.${personPhone.countryCode}.${personPhone.areaCode}.${personPhone.phoneNumber}`,
                  });
                }
              }}
            />
          </FormItem>
          {action !== "create" && (
            <>
              <FormItem label="Additional Phones">
                <PhoneInputs
                  action="update"
                  phones={phones}
                  setPhones={setPhones}
                  phoneTypes={phoneTypes}
                />
              </FormItem>

              <Button
                type="primary"
                style={{ margin: "16px 0px" }}
                onClick={() =>
                  setPhones([
                    ...phones,
                    {
                      phoneId: uuidv4(),
                      phoneType: "",
                      phoneNumber: "",
                      newPhone: true,
                    },
                  ])
                }
              >
                <PlusCircleOutlined /> Add Phone
              </Button>
            </>
          )}
          <Form.Item>
            {action === "create" ? (
              ""
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Save
              </Button>
            )}
            {errorMessage && (
              <p
                style={{
                  textAlign: "center",
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                {DOMPurify.sanitize(errorMessage)}
              </p>
            )}
          </Form.Item>
        </Form>
      ) : (
        <>
          {personPrimaryPhone && (
            <p>PRIMARY {renderPhone(personPrimaryPhone)}</p>
          )}
          {personPhones.length ? (
            <List
              className="list"
              itemLayout="horizontal"
              dataSource={personPhones}
              renderItem={(phone) => (
                <List.Item>
                  {DOMPurify.sanitize(phone.phoneType || "")}{" "}
                  {renderPhone(DOMPurify.sanitize(phone.phoneNumber))}
                </List.Item>
              )}
            />
          ) : (
            ""
          )}
        </>
      )}
    </Card>
  );
};

export default PersonPhones;
