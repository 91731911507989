import { EditOutlined, StarFilled, StarOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Checkbox,
  ColorPicker,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  List,
  Select,
  Tag,
} from "antd";
import { FormInstance } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { UpdateTasksDocument } from "../../../../../generated/graphql";
import { useMutation } from "@apollo/client";
import { Color } from "antd/es/color-picker";
import DOMPurify from "dompurify";

interface Task {
  taskId: string;
  taskName: string;
  taskDescription?: string;
  taskDueDate?: string;
  taskFavorite?: boolean;
  taskColor?: string;
  taskStatus?: string;
}

interface TaskStatus {
  name: string;
}

interface PersonTaskListProps {
  personTasks?: Task[];
  refetchTasks?: () => void;
  taskStatuses: TaskStatus[];
  personId: string;
  loggedUserId: string;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}

const PersonTaskList: React.FC<PersonTaskListProps> = ({
  personTasks,
  refetchTasks,
  taskStatuses,
  personId,
  loggedUserId,
  setEditMode,
  setRefetch,
}) => {
  const [form] = Form.useForm();
  const [updateTask] = useMutation(UpdateTasksDocument);
  const [colorHex, setColorHex] = useState<string | Color>("#1677ff");
  const [dueDate, setDueDate] = useState<string | null | undefined>(null);
  const [favorite, setFavorite] = useState<boolean>(false);
  const [editTask, setEditTask] = useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState<any>();

  useEffect(() => {
    if (personTasks && editTask && selectedTask.length) {
      const task = personTasks.find((task) => task.taskId === selectedTask);
      if (!task) return;
      form.setFieldsValue({
        taskName: task.taskName,
        taskDescription: task.taskDescription,
        taskStatus: task.taskStatus,
        taskDueDate: task.taskDueDate
          ? dayjs(task.taskDueDate, "YYYY-MM-DD")
          : undefined,
        taskFavorite: task.taskFavorite,
      });
      setColorHex(task.taskColor ?? "#1677ff");
    }
  }, [personTasks, editTask, selectedTask, form]);

  const findTask = (taskId: string) => {
    return personTasks?.find((task) => task.taskId === taskId);
  };

  const hexString = useMemo<string>(
    () => (typeof colorHex === "string" ? colorHex : colorHex?.toHexString()),
    [colorHex]
  );

  const onDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    if (typeof dateString === "string" && dateString.length > 0) {
      setDueDate(dateString);
    }
  };

  const handleFormSubmit = async (values: any) => {
    try {
      const createData = {
        ...values,
        taskUpdatedAt: dayjs().format("YYYY-MM-DD"),
      };

      await updateTask({
        variables: {
          update: {
            ...createData,
            taskDueDate: dueDate ? DOMPurify.sanitize(dueDate) : undefined,
            taskColor: DOMPurify.sanitize(hexString ? hexString : "#1677ff"),
            taskFavorite: favorite,
          },
          where: {
            taskOwner: { userId: loggedUserId },
            taskId: selectedTask,
            taskPerson_SINGLE: { personId: personId },
          },
        },
      }).then((res) => {
        if (!res.errors) {
          form.resetFields();
          setEditTask(false);
          setSelectedTask("");
          setRefetch(true);
        }
      });
    } catch (error) {
      console.error("Error creating a task", error);
    }
  };

  return (
    <List
      className="task-list"
      itemLayout="horizontal"
      dataSource={personTasks}
      renderItem={(task) => (
        <List.Item>
          {selectedTask !== task.taskId ? (
            <>
              <div>
                {task.taskFavorite ? (
                  <b className="favorite-task">
                    <StarFilled
                      style={{
                        color: "#ffc53d",
                        fontSize: "24px",
                        paddingRight: "8px",
                      }}
                    />{" "}
                    {DOMPurify.sanitize(task.taskName)}
                  </b>
                ) : (
                  <Badge
                    color={task.taskColor ? task.taskColor : "#1677ff"}
                    text={DOMPurify.sanitize(task.taskName)}
                    style={{ fontWeight: "bold" }}
                  />
                )}
                {task.taskDescription && (
                  <p className="text" style={{ color: "#8c8c8c" }}>
                    {DOMPurify.sanitize(task.taskDescription)}
                  </p>
                )}
                {task.taskDueDate && (
                  <p>
                    Due :{" "}
                    {DOMPurify.sanitize(
                      dayjs(task.taskDueDate).format("MM-DD-YYYY")
                    )}
                  </p>
                )}
              </div>
              <div>
                {refetchTasks
                  ? taskStatuses?.map((status) => (
                      <Tag.CheckableTag
                        key={`${task.taskId}-${status.name}`}
                        checked={
                          findTask(task.taskId)?.taskStatus === status.name &&
                          findTask(task.taskId)?.taskId === task.taskId
                            ? true
                            : false
                        }
                        onChange={async (checked) => {
                          if (checked) {
                            await updateTask({
                              variables: {
                                update: {
                                  taskStatus: status.name,
                                  taskUpdatedAt: dayjs().format("YYYY-MM-DD"),
                                },
                                where: {
                                  taskId: task.taskId,
                                  taskPerson_SINGLE: {
                                    personId: personId,
                                  },
                                  taskOwner: { userId: loggedUserId },
                                },
                              },
                            }).then(() => {
                              refetchTasks();
                            });
                          }
                        }}
                      >
                        {DOMPurify.sanitize(status.name.replace(/_/g, " "))}
                      </Tag.CheckableTag>
                    ))
                  : ""}
              </div>
            </>
          ) : (
            <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
              <Form.Item
                label="Mark as Priority :"
                name="taskFavorite"
                className="favorite-checkbox"
              >
                <Checkbox
                  className="platform-checkbox"
                  onChange={(e) => {
                    setFavorite(e.target.checked);
                  }}
                >
                  {favorite ? (
                    <StarFilled
                      style={{
                        color: "#ffc53d",
                        fontSize: "32px",
                      }}
                    />
                  ) : (
                    <StarOutlined
                      style={{
                        color: "#ffc53d",
                        fontSize: "32px",
                      }}
                    />
                  )}
                </Checkbox>
              </Form.Item>
              <Form.Item
                label="Task Name"
                name="taskName"
                rules={[{ required: true, message: "Task Name is required" }]}
              >
                <Input
                  placeholder="Enter task name"
                  onChange={(e) =>
                    form.setFieldsValue({
                      taskName: DOMPurify.sanitize(e.target.value),
                    })
                  }
                />
              </Form.Item>
              <Form.Item label="Task Description" name="taskDescription">
                <Input.TextArea
                  placeholder="Enter task description"
                  onChange={(e) =>
                    form.setFieldsValue({
                      taskName: DOMPurify.sanitize(e.target.value),
                    })
                  }
                  //   defaultValue={defaultData?.taskDescription}
                />
              </Form.Item>
              <Form.Item
                label="Task Status"
                name="taskStatus"
                rules={[{ required: true, message: "Task Status is required" }]}
              >
                <Select placeholder="Select a task status">
                  {taskStatuses &&
                    taskStatuses.map((status: any) => (
                      <Select.Option key={status.name} value={status.name}>
                        {status.name.replace(/_/g, " ")}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item label="Task Due Date" name="taskDueDate">
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Select due date"
                  onChange={onDateChange}
                />
              </Form.Item>
              <Form.Item label="Task Color" name="taskColor">
                <ColorPicker value={colorHex} onChange={setColorHex} />
                <span style={{ display: "none" }}>{hexString}</span>{" "}
                {/* Need span to display color in component, don't know why yet */}
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Form>
          )}
          {editTask && selectedTask === task.taskId ? (
            <Button
              type="link"
              onClick={() => {
                setEditTask(false);
                setSelectedTask("");
                setFavorite(false);
              }}
            >
              {personId !== "" ? "Cancel" : ""}
            </Button>
          ) : (
            <Button
              type="link"
              onClick={() => {
                setEditTask(true);
                setSelectedTask(task.taskId);
                setFavorite(task?.taskFavorite ?? false);
              }}
            >
              <EditOutlined />
            </Button>
          )}
        </List.Item>
      )}
    />
  );
};

export default PersonTaskList;
