import {
  CompassOutlined,
  EditOutlined,
  HeartFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Button, Card, Form, List, Tooltip } from "antd";
import FormItem from "antd/es/form/FormItem";
import React, { useEffect } from "react";
import PlatformInputs from "../form-components/PlatformInputs";
import {
  UpdatePeopleDocument,
  UpdatePlatformDocument,
  useGetSocialMediaPlatformsQuery,
} from "../../../../generated/graphql";
import { v4 as uuidv4 } from "uuid";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";

interface PersonPlatformsProps {
  personId: string;
  personPlatforms: any[];
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}

const PersonPlatforms: React.FC<PersonPlatformsProps> = ({
  personId,
  personPlatforms,
  setRefetch,
}) => {
  const { action } = useParams();
  const [editMode, setEditMode] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [platforms, setPlatforms] = React.useState<any>([]);
  const [updatePerson] = useMutation(UpdatePeopleDocument);
  const [updatePlatform] = useMutation(UpdatePlatformDocument);
  const { data: platformList } = useGetSocialMediaPlatformsQuery();

  useEffect(() => {
    setPlatforms(
      personPlatforms.map((platform: any) => ({
        platformId: platform.platformId,
        platformName: platform.platformName,
        platformUrl: platform.platformUrl,
        platformFavorite: platform.platformFavorite,
        newPlatform: false,
      }))
    );
    if (action === "create" && editMode && platforms.length === 0) {
      setPlatforms([
        {
          platformId: uuidv4(),
          platformName: "",
          platformUrl: "",
          platformFavorite: false,
          newPlatform: true,
        },
      ]);
    }
  }, [editMode, personPlatforms]);

  const handleSubmit = async (values: any) => {
    const dateTime = new Date().toISOString();

    const platformCleanList = platforms.filter(
      (platform: any) =>
        platform.platformName.length && platform.platformUrl.length
    );
    try {
      const handleCreatePlatforms = () => {
        const list = [...platformCleanList];
        if (list.length && list[0].platformName) {
          const newPlatforms = list.filter((platform) => platform.newPlatform);

          if (newPlatforms.length) {
            return newPlatforms.map((platform) => ({
              node: {
                platformId: platform.platformId,
                platformName: platform.platformName,
                platformUrl: platform.platformUrl,
                platformFavorite: platform.platformFavorite,
                platformOwner: {
                  connect: { where: { node: { personId: personId } } },
                },
              },
            }));
          }
        }
      };
      const handleUpdatePlatforms = async () => {
        try {
          const list = [...platformCleanList];
          if (list.length && list[0].platformName && personId) {
            const modifiedPlatforms = list.filter(
              (platform) =>
                !platform.newPlatform && platform.modified === "update"
            );
            if (modifiedPlatforms.length) {
              await Promise.all(
                modifiedPlatforms.map((platform) =>
                  updatePlatform({
                    variables: {
                      where: {
                        platformId: platform.platformId,
                        platformOwner: { personId: personId },
                      },
                      update: {
                        platformName: platform.platformName,
                        platformUrl: platform.platformUrl,
                        platformFavorite: platform.platformFavorite,
                      },
                    },
                  })
                )
              );
              setEditMode(false);
              setRefetch(true);
            } else {
              setEditMode(false);
              setRefetch(true);
            }
          } else {
            setEditMode(false);
            setRefetch(true);
          }
        } catch (error: any) {
          console.error("[Failed to update Platform]", error);
          if (error.graphQLErrors) {
            console.error(
              "[PERSON FORM - GraphQL Error: ]" +
                error.graphQLErrors.map((e: any) => e.message).join(", ")
            );
          }
          if (error.networkError) {
            console.error(
              "[PERSON FORM - Network Error: ]" + error.networkError.message
            );
          }
        }
      };
      const handleDeletePlatforms = () => {
        const list = [...platformCleanList];
        if (list.length && list[0].platformName && personId) {
          const deletedPlatforms = list.filter(
            (platform) =>
              !platform.newPlatform && platform.modified === "delete"
          );
          if (deletedPlatforms.length) {
          }

          return deletedPlatforms.map((platform) => ({
            where: {
              node: {
                platformId: platform.platformId,
                platformOwner: { personId: personId },
              },
            },
          }));
        }
      };

      const response = await updatePerson({
        variables: {
          update: {
            personPlatforms: {
              create: handleCreatePlatforms(),
              delete: handleDeletePlatforms(),
            },
            personUpdatedAt: dateTime,
          },
          where: { personId: personId },
        },
      });

      if (response.errors) {
        setErrorMessage("ERROR TO UPDATE PERSON" + response.errors[0].message);
      } else {
        handleUpdatePlatforms();
      }
    } catch (e) {
      console.error("ERROR UPDATING PERSON'S PLATFORMS: " + e);
    }
  };

  return (
    <Card
      className="card-children"
      title={
        <>
          <CompassOutlined /> Platforms
        </>
      }
      extra={
        action !== "create" && (
          <Button type="link" onClick={() => setEditMode(!editMode)}>
            {editMode ? (
              "Cancel"
            ) : personId !== "" ? (
              <EditOutlined />
            ) : (
              <>
                <PlusCircleOutlined /> Add
              </>
            )}
          </Button>
        )
      }
    >
      {editMode ? (
        <Form layout="vertical" onFinish={handleSubmit}>
          <FormItem name="platforms">
            <PlatformInputs
              action="update"
              platforms={platforms}
              setPlatforms={setPlatforms}
              platformList={platformList}
            />
          </FormItem>
          <Button
            type="primary"
            style={{ margin: "16px 0px" }}
            onClick={() =>
              setPlatforms([
                ...platforms,
                {
                  platformId: uuidv4(),
                  platformName: "",
                  platformUrl: "",
                  platformFavorite: false,
                  newPlatform: true,
                },
              ])
            }
          >
            <PlusCircleOutlined /> Add Platform
          </Button>
          <Form.Item>
            {action === "create" ? (
              ""
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Save
              </Button>
            )}
            {errorMessage && (
              <p
                style={{
                  textAlign: "center",
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                {DOMPurify.sanitize(errorMessage)}
              </p>
            )}
          </Form.Item>
        </Form>
      ) : personPlatforms.length ? (
        <List
          className="list"
          itemLayout="horizontal"
          dataSource={personPlatforms}
          renderItem={(platform) => (
            <List.Item>
              {DOMPurify.sanitize(platform.platformName)}{" "}
              {platform.platformUrl ? (
                <Button
                  type="link"
                  href={DOMPurify.sanitize(platform.platformUrl)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact Link
                </Button>
              ) : (
                <span style={{ color: "red" }}>Invalid URL</span>
              )}
              {platform.platformFavorite ? (
                <Tooltip title="Preferred way of communication">
                  <HeartFilled style={{ color: "#ff5d8f", fontSize: "20px" }} />
                </Tooltip>
              ) : (
                <></>
              )}
            </List.Item>
          )}
        />
      ) : (
        "No Platforms"
      )}
    </Card>
  );
};

export default PersonPlatforms;
