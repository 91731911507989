import React, { useState, useEffect } from "react";
import { AgCharts } from "ag-charts-react";
import DOMPurify from "dompurify";

const DoughnutChart = ({ dataList, colors }) => {
  const [legendPosition, setLegendPosition] = useState("right");

  const sanitizedData = dataList.map((item) => ({
    label: DOMPurify.sanitize(item.label),
    amount: Number(item.amount) || 0,
  }));

  useEffect(() => {
    const updateLegendPosition = () => {
      if (window.matchMedia("(max-width: 1024px)").matches) {
        setLegendPosition("bottom");
      } else {
        setLegendPosition("right");
      }
    };
    updateLegendPosition();
    window.addEventListener("resize", updateLegendPosition);
    return () => window.removeEventListener("resize", updateLegendPosition);
  }, []);

  const options = {
    autosize: true,
    data: sanitizedData,
    series: [
      {
        type: "pie",
        angleKey: "amount",
        labelKey: "label",
        legendItemKey: "label",
        fills: colors.map((color) => DOMPurify.sanitize(color)),
        strokes: ["#ffffff"],
      },
    ],
    title: {
      text: "",
      fontSize: 0,
    },
    legend: {
      enabled: true,
      position: legendPosition,
    },
  };

  return <AgCharts options={options} />;
};

export default DoughnutChart;
